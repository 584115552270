.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #FAFAFA;
    .button {
        margin-top: 30px;
    }
    .appBar{
        box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
        .toolbar{
            background-color: white ;
            justify-content: center;
        }
    }
}