// *{
//     &::-webkit-scrollbar{
//         width: 0.1rem;
//         background-color: rgba(255,255,255,.15); 
//     }
//     &::-webkit-scrollbar-thumb {
//         background: rgba(255,255,255,1); 
//     }
// }

body{
    padding-right: 0 !important;
}

*{
    scrollbar-width: thin;
    scrollbar-color: #6EAED1 #CFD8DC;
    &::-webkit-scrollbar{
        width: 7px;
        height: 100%;
    }
    &::-webkit-scrollbar-track{
        background: #f7fafb;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #999 ;
        border-radius: 6px;
        //border: 0px solid #CFD8DC;
    }
}

.MuiGrid-spacing-xs-2{
    width: calc(100% - 8px);
}