.details-warehouse::after {
  // Create a vertical line as a divider
  content: '';
  position: absolute;
  bottom: 20px;
  top: 20px;
  right: 0;
  width: 1px;
  height: calc(100% - 40px);
  // Translate to center
  // transform: translate(0, 50%);
  background-color: #e0e0e0;

  @media screen and (max-width: 1250px) {
    display: none;
  }
}

.details-warehouse{
  // padding-top: 40px;
  position: relative;
}