.MuiAlert-message {
    padding: 10px 0 !important;
}

body {
    overflow: auto;
    line-height: unset !important;
}

.MuiInputBase-root {
    border-radius: 0% !important;
}

.tawk-min-container {
    display: none !important;
}