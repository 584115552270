// @import '../../../../node_modules/draft-js/dist/Draft.css';
@import '../../../../node_modules/react-quill/dist/quill.snow.css';

.product-selector-textfield-number {
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {
        // height: 100%;  
        opacity: 1;
    }

    input {
        padding-top: 8px;
        padding-bottom: 7px;
        padding-right: 5px;
    }
}

.DraftEditor-root {
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 4px;
    padding: 10px;
    min-height: 100px;
}
.MuiRadio-colorPrimary.Mui-checked{
    color: #6EAED1 !important;
}

.ql-editor {
    min-height: 120px;
    @media screen and (max-width: 1450px){
        min-height: 100px;
    }
    @media screen and (max-width: 1280px){
        min-height: 80px;
    }
    @media screen and (max-width: 1000px){
        min-height: 60px;
    }
}

.editor-button {
    width: 30px;
    height: 30px;
    background-color: #ddd;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    user-select: none;

    &.active {
        border: solid 1px #aaa;
    }

    &.bold {
        font-weight: bold;
    }

    &.underline {
        text-decoration: underline;
    }

    &.italic {
        font-style: italic;
    }
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    @media screen and (max-width: 1450px){
        padding: 4px;
    }
}

.ql-container.ql-snow {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.singleSelectTree.react-dropdown-tree-select {
    @font-face {
        font-family: "Material Icons";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
            format("woff2");
    }
    .dropdown {
        width: 100%;

        .dropdown-trigger.arrow {
            width: 100%;

            &.top:after,
            &.bottom:after {
                position: absolute;
                right: 10px;
                top: 15px;
                font-size: 10px;
            }
        }

        .dropdown-content {
            width: 100%;
            z-index: 11;
            .search {
                height: 40px;
                margin-bottom: 10px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
            }

            .no-matches {
                display: block;
                margin: 15px;
            }

            ul {
                margin: 15px;
            }
        }

        .dropdown-trigger {
            padding: 7px;
            border-radius: 4px;

            .tag-item {
                margin-left: 0px;

                &:nth-child(2) {
                    display: none;
                }

                .placeholder {
                    font-size: 16px;
                }

                .tag {
                    background-color: inherit;
                    border: 0;
                    padding: 0;
                    font-size: 16px;
                    .tag-remove {
                        display: none;
                    }
                }
            }
        }
    }

    .checkbox-item, .radio-item {
        display: none;
    }
    .material-icons {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }
    .dropdown-trigger > span:after {
        font-size: 12px;
        color: #555;
    }
}

.multiSelectTree.react-dropdown-tree-select {
    /* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
    @font-face {
        font-family: "Material Icons";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
            format("woff2");
    }

    &.error{
        .dropdown .dropdown-trigger.arrow{
            border-color: #f44336;
        }
    }
    
    .material-icons {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }
    .dropdown-trigger > span:after {
        font-size: 12px;
        color: #555;
    }
    
    .toggle {
        white-space: pre;
        margin-right: 4px;
        outline: none;
        position: absolute;
        top: 2px;
    &:after{
        content: "";
        border: solid #555;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    }
    
    .toggle.collapsed::after {
    content: "";
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
    
    
    /* checkbox styles */
    .checkbox-item {
        position: relative;
        width: 1rem;
        height: 1rem;
        margin-right: 0.75rem;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        vertical-align: middle;
    }
    
    .checkbox-item:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border: 2px solid #aaa;
        transition: all 0.3s ease-in-out;
    }
    
    .checkbox-item:checked:before {
        height: 50%;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-top-style: none;
        border-right-style: none;
        border-color: #6EAED1;
    }
    .node{
        margin-left: 10px;
        position: relative;
        &[aria-checked="true"]{
            label{
                color: #6EAED1;
            }
        }
        > label{
            margin-left: 20px;
        }
    }
    .dropdown {
        width: 100%;

        .dropdown-trigger.arrow {
            width: 100%;
            min-height: 50px;
            &.top:after,
            &.bottom:after {
                position: absolute;
                right: 10px;
                top: 17px;
                font-size: 10px;
            }
        }

        .dropdown-content {
            width: 100%;
            z-index: 11;
            .search {
                height: 40px;
                margin-bottom: 10px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
            }

            .no-matches {
                display: block;
                margin: 15px;
            }

            ul {
                margin: 15px;
                .infinite-scroll-component{
                    max-height: 350px;
                }
            }
        }

        .dropdown-trigger {
            padding: 7px;
            border-radius: 4px;

            .tag-item {
                margin-left: 0px;
                &:nth-last-child(n+2) ~ li:last-child{
                    display: none;
                }
            }
        }
    }
}

.error-label {
    color: #f44336;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: soleil, sans-serif;
    font-weight: 400;
    line-height: 1.66;
}

.quill.error {
    .ql-toolbar, .ql-container {
        border-color: #f44336;
    }
}