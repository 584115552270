.blockContainer{
    display: flex;
    flex-direction: column;
    height: 65vh;
    background: white;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
    border-radius: 5px;
    padding: 2rem;
    &.selectCategorie{
        box-shadow: inherit;
        padding: .5rem;
        width: 500px;
        .rstcustom__rowWrapper{
            &:hover{
                background: #f0faf2;
            }
            cursor: pointer;
        }
        .containerTree{
            .icons{
                fill: #6ed17b !important;
            }
            h5{
                color: #6ed17b;
            }
            .treeContent{
                .rstcustom__row.rst__selected-node{
                    background: #deffe5;
                    span{
                        color: #6ed17b;
                    }
                }
            }
        }
    }
    .containerTree{
        padding: 1rem 0;
        height: 100%;
        background: #FAFBFB;
        position: relative;
        &.topSpace{
            .treeContent{
                margin-top: 30px;
            }
        }
        .buttonAll{
            border: none;
            background: none;
            position: relative;
            padding: 0;
            cursor: pointer;
            outline: none;
            margin-left: 1rem;
            &:hover{
                h4{
                    text-decoration: underline;
                }
            }
            h4{
                color: #6EAED1;
            }
        }
        .containerButtons{
            position: absolute;
            right: 1rem;
            top: 1rem;
            z-index: 10;
            button{
                border: none;
                background: none;
                position: relative;
                padding: 0;
                cursor: pointer;
                outline: none;
                margin-right: 1rem;
                &:first-child{
                    margin-right: 18px;
                    &:after{
                        content: "";
                        height: 16px;
                        width: 1px;
                        background: #36234A;
                        position: absolute;
                        right: -10px;
                        top: 0.4rem;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
                &:hover{
                    h4{
                        text-decoration: underline;
                    }
                }
                h4{
                    color: #6EAED1;
                }
            }
        }
        .treeContent{
            flex: 1 0 50%;
            height: 100%;
            &.reducedTree{
                margin-top: 30px;
            }
            .rstcustom__row.rst__selected-node{
                width: 100%;
                background: #F0F7FA;
                .rstcustom__rowContents{
                    justify-content: left;
                }
            }
        }
        .icons{
            fill: #6EAED1 !important;
            outline: none;
        }
    }
}
.rstcustom__rowSearchFocus{
    box-shadow: none;
}
.rstcustom__rowSearchMatch{
    border-bottom: 1px #6EAED1;
}
.rstcustom__rowLabel{
    margin-left: 0.25rem;
}
.rstcustom__collapseButton, .rstcustom__expandButton{
    width: 20px;
    margin-left: 1rem;
    outline: none;
    height: 100%;
    &:after{
        border: solid #6EAED1;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        top: 9px;
        left: 4px;
    }
}
.rstcustom__collapseButton:focus::after, .rstcustom__expandButton:focus::after{
    filter: none;
}

.rstcustom__expandButton::after{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.rstcustom__collapseButton::after{
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.rstcustom__rowSearchMatch{
    box-shadow: none;
    // border-bottom: 1px solid #6EAED1;
    .rstcustom__rowLabel span{
        background: yellow;
    }
}
.rstcustom__rowWrapper{
    &:hover{
        background: #F0F7FA;
        opacity: 1;
        .treeOptions{
            display: block !important;
        }
    }
    > div{
        height: 100%;
    }
    .rstcustom__row{
        // top: 0.25rem;
        margin-left: -0.5rem;
        left: 1rem;
        .rstcustom__rowToolbar{
            .treeOptions{
                padding: 0 ;
                margin-right: 1rem;
                color: #6EAED1;
                text-decoration: underline;
                vertical-align: middle;
                font-weight: 100;
                cursor: pointer;
                display: none;
                &.type-info{
                    cursor: auto;
                    >span{
                        >span{
                            position: relative;
                            transform: none;
                            padding: 0 10px;
                        }
                    }
                }
                &:hover{
                    h5{
                        text-decoration: underline;
                    }
                }
                svg{
                    width: 16px;
                    height: 16px;
                    margin-right: 0.25rem;
                    display: inline-block;
                    vertical-align: middle;
                }
                h5{
                    display: inline-block;
                    vertical-align: middle;
                    line-height: normal;
                }
            }
        }
    }
}
